import React from "react"
import styled from "styled-components"
import { Container } from "@revfluence/aspire-www-ui"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Flex, Box } from "rebass"

const WhiteHeader = styled.h1`
  color: white;
`

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Flex
        width="100%"
        css={`
          min-width: 90vw;
          height: 700px;
          background: url(${data.placeholderImage.childImageSharp.fluid.src})
              no-repeat center 150px,
            #276ee1;
          justify-content: center;
          overflow: hidden;
        `}
      >
        <Container>
          <Box
            css={`
              color: white;
              text-align: center;
              margin-top: 150px;
            `}
          >
            <WhiteHeader>Content Unavailable.</WhiteHeader>
            <p>Let's try again.</p>
          </Box>
        </Container>
      </Flex>
    </Layout>
  )
}

export default NotFoundPage
export const pageQuery = graphql`
  query {
    placeholderImage: file(relativePath: { regex: "/404-hero/" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
